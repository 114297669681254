import MonoRail from '@parameter1/base-cms-marko-web-theme-monorail/browser';
import Leaders from '@parameter1/base-cms-marko-web-leaders/browser';

const ContentMeterTrack = () => import(/* webpackChunkName: "content-meter-tracker" */ './track-content-meter.vue');
const PrestitialCookie = () => import(/* webpackChunkName: "global-prestitial-cookie" */ './prestitial-cookie.vue');
const SuperstitialCookie = () => import(/* webpackChunkName: "global-superstitial-cookie" */ './superstitial-cookie.vue');
const BBMForm = () => import(/* webpackChunkName: "bbm-form" */ './bbm-form.vue');
const GlobalNodeCarousel = () => import(/* webpackChunkName: "global-node-carousel" */ './node-carousel.vue');
const OOPAdHandler = () => import('./oop-ad-handler.vue');
const GlobalToaster = () => import('./toaster.vue');

export default (Browser) => {
  MonoRail(Browser);
  Leaders(Browser);
  Browser.register('ContentMeterTrack', ContentMeterTrack);
  Browser.register('GlobalPrestitialCookie', PrestitialCookie);
  Browser.register('GlobalSuperstitialCookie', SuperstitialCookie);
  Browser.register('BBMForm', BBMForm);
  Browser.register('GlobalNodeCarousel', GlobalNodeCarousel);
  Browser.register('GlobalOOPAdHandler', OOPAdHandler);
  Browser.register('GlobalToaster', GlobalToaster);
};
